<template>
  <div>
    
    <!-- If admin has selected to show the top company -->
    <!-- it will be automatically selected -->
    <v-row class="mt-8" v-if="root_company.show">
      <v-col>

        <div class="boxed-select">
          <p class="boxed-header mb-4">{{ company_select_labels[0] }}</p>
          <div class="checkbox-list">
            <div class="d-flex align-items-center mb-6">
              <label class="radio radio-outline radio-success mr-5">
                <input
                  type="radio"
                  v-model="root_company_visual_select"
                  name="root_company_visual_select"
                  :value="root_company.company_id"
                  @change="level_was_selected(0)"
                />
                <span class="mr-2"></span>{{ root_company.name }}
              </label>
            </div>
          </div>
        </div>

      </v-col>
    </v-row>

    <!-- next level 1 -->
    <v-row class="mt-8 level1-selection" v-if="show_level_1">
      <v-col>

        <div class="boxed-select">
          <p class="boxed-header mb-4">{{ company_select_labels[1] }}</p>

          <div class="checkbox-list">
            <template v-for="(level1, i) of companies_level_1">
              <div :key="i" v-if="level1.show">
                <div class="d-flex align-items-center mb-6">
                  <label class="radio radio-outline radio-success mr-5">
                    <input
                      type="radio"
                      v-model="company_level_1_select"
                      name="company_level_1_select"
                      :value="level1.company_id"
                      @change="level_was_selected(1)"
                    />
                    <span class="mr-2"></span>{{ level1.name }}
                  </label>
                </div>
              </div>
            </template>
          </div>
        </div>

      </v-col>
    </v-row>

    <!-- next level 2 -->
    <v-row class="mt-8 level2-selection" v-if="companies_level_2.length > 0">
      <v-col>
        <div class="boxed-select">
          <p class="boxed-header mb-4">{{ company_select_labels[2] }}</p>
          <div class="checkbox-list">
            <template v-for="(level2, i) of companies_level_2">
              <div :key="i" v-if="level2.show">
                <div class="d-flex align-items-center mb-6">
                  <label class="radio radio-outline radio-success mr-5">
                    <input
                      type="radio"
                      v-model="company_level_2_select"
                      name="company_level_2_select"
                      :value="level2.company_id"
                      @change="level_was_selected(2)"
                    />
                    <span class="mr-2"></span>{{ level2.name }}
                  </label>
                </div>
              </div>
            </template>
          </div>
        </div>
      </v-col>
    </v-row>

    <!-- next level 3 -->
    <v-row class="mt-8 level3-selection" v-if="companies_level_3.length > 0">
      <v-col>
        <div class="boxed-select">
          <p class="boxed-header mb-4">{{ company_select_labels[3] }}</p>
          <div class="checkbox-list">
            <template v-for="(level3, i) of companies_level_3">
              <div :key="i" v-if="level3.show">
                <div class="d-flex align-items-center mb-6">
                  <label class="radio radio-outline radio-success mr-5">
                    <input
                      type="radio"
                      v-model="company_level_3_select"
                      name="company_level_3_select"
                      :value="level3.company_id"
                      @change="level_was_selected(3)"
                    />
                    <span class="mr-2"></span>{{ level3.name }}
                  </label>
                </div>
              </div>
            </template>
          </div>
        </div>
      </v-col>
    </v-row>


    <!-- Extras options -->
    <v-row>
      <v-col>
        <div class="mt-16" v-if="root_company.extra_option !== null && root_company.extra_option !== undefined">

          <div class="boxed-select">
            <p class="boxed-header mb-4">{{ company_select_labels[4] }}</p>

            <ExtraCompanyMultipleOptions
              v-if="root_company.extra_option === 'checkbox'"
              :companies="extra_company_options"
              :extra_visibility_option="root_company.extra_visibility_option"
              @changed="extra_company_ids_changed"
            />

            <ExtraCompanySingleOption
              v-if="root_company.extra_option === 'radio'"
              :companies="extra_company_options"
              :extra_visibility_option="root_company.extra_visibility_option"
              @changed="extra_company_ids_changed"
            />
          </div>
        </div>
      </v-col>
    </v-row>

    <!-- Show which companies you will register membership in -->
    <v-row class="mt-8" v-if="names_of_companies_memberships !== ''">
      <v-col>
        <p style="color: #949494;">{{ $t('PUBLIC.REGISTRATION.YOU_WILL_BE_MEMBER') }} {{ names_of_companies_memberships }}</p>
      </v-col>
    </v-row>
    
    
    <p v-if="show_error" class="mt-8" style="color: #fd397a;">{{ $t('PUBLIC.FORM.MISSING_FIELDS') }}</p>

    <div class="d-sm-flex justify-content-end align-items-center align-bottom w-100">
      <b-button
        v-if="form_page.index != 0"
        class="mt-4 mr-4"
        variant="outline-secondary"
        @click.prevent="on_back"
      >
        {{ $t('COMMON.BACK') }}
      </b-button>
      
      <b-button
        class="mt-4"
        variant="primary"
        :type="type"
        :disabled="disabled || spinning"
        @click.prevent="on_click"
      >
        <b-spinner v-if="spinning" small type="grow"></b-spinner>
        {{ $t('COMMON.CONTINUE') }}
      </b-button>
    </div>

  </div>
</template>

<script>
import dayjs from 'dayjs';

import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import ExtraCompanyMultipleOptions from '@/view/pages/members/register/registerwizard/company_select_v2/ExtraCompanyMultipleOptions.vue';
import ExtraCompanySingleOption from '@/view/pages/members/register/registerwizard/company_select_v2/ExtraCompanySingleOption.vue';
import axios from 'axios';


export default {
  name: 'FormPageCompanySelect',
  mixins: [ toasts ],

  props: {
    
    form_page_data: { /* data for the entire form, previously entered, all pages */
      type: Object,
      default: () => ({})
    },

    form_page: { /* page definition for this page, ml_form_page */
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    show_level_1() {
      const found = this.companies_level_1.filter(item => item.show);

      return found.length > 0;
    },

    names_of_companies_memberships() {

      if (!this.local_form_page_data || !this.local_form_page_data.company_ids) {
        return '';
      }

      return this.local_form_page_data.company_ids.map((company_id) => {
        
        const company = this.all_companies[company_id];
        
        return company ? company.name : '';

      }).join(', ')
    }

  },
  

  components: {
    ExtraCompanySingleOption,
    ExtraCompanyMultipleOptions
  },

  data() {
    return {
      local_form_page_data: {},

      all_companies: {}, // { [company_id]: company }

      extra_company_options: [],

      root_company: {},

      company_select_labels: [],

      companies_level_1: [],
      companies_level_2: [],
      companies_level_3: [],
      companies_level_4: [],

      root_company_visual_select: null,

      company_level_1_select: null,
      company_level_2_select: null,
      company_level_3_select: null,
      company_level_4_select: null,

      main_company_selected: null,
      extra_company_ids_selected: [],

      skip: false,

      force_validate: 0,
      is_form_valid: false,
      show_error: false,
      spinning: false,
      disabled: false,
      type: 'primary'
    };
  },
  mounted() {

    console.log('local_form_page', this.form_page);
    console.log('local_form_page_data', this.form_page_data);

    this.local_form_page_data = { ...this.form_page_data }

    if (this.local_form_page_data.company_ids === undefined) {
      this.local_form_page_data.company_ids = []; 
    }

    this.setup_registration_hierarchy_view(this.form_page);
  },
  watch: {
    form_page_data: {
      handler(newValue) {
        
        this.local_form_page_data = { ...this.form_page_data }

        if (this.local_form_page_data.company_ids === undefined) {
          this.local_form_page_data.company_ids = []; 
        }
      },
      deep: true
    }
  },
  methods: {
    
    on_back() {
      this.$emit('back', this.form_page, this.local_form_page_data);
    },

    skip_clicked() {},

    update_form(field_name, value) {
      this.local_form_page_data[field_name] = value;

      this.local_form_page_data = { ...this.local_form_page_data };
    },
    validated(field_name, value, valid) {
      this.local_form_page_data[field_name] = value;

      if (!valid) {
        this.is_form_valid = false;
      }
    },

    async on_click() {
      this.spinning = true;
      this.show_error = false;

      if (this.local_form_page_data.company_ids.length === 0) {
        this.is_form_valid = false;
      }

      this.is_form_valid = true;
      this.force_validate = this.force_validate + 1;

      this.$nextTick(()=>{
        if (this.is_form_valid) {
          this.spinning = false;

          this.$emit('next', this.form_page, this.local_form_page_data);
        }
        else {
          this.show_error = true; 

          setTimeout(()=>{
            this.spinning = false;
          }, 2000);
        }
      });
    },

    level_was_selected(level) {

      let target_level = null;
      let current_level = null;

      /// popuplate the next level
      switch (level) {
        case 0:
          target_level = this.companies_level_1;
          current_level = this.root_company;
          break;
        case 1:
          target_level = this.companies_level_2;
          current_level = this.companies_level_1;
          break;
        case 2:
          target_level = this.companies_level_3;
          current_level = this.companies_level_2;
          break;
        case 3:
          target_level = this.companies_level_4;
          current_level = this.companies_level_3;
          break;
      }

      /// find the selected company, actually works
      const selected_company = current_level.find((company) => {
        return company.company_id === this[`company_level_${level}_select`];
      });

      if (selected_company.membership) {
        this.main_company_selected = selected_company;

        console.log('time to emit page form')

        this.assign_company_ids();
        
        // this.emit_page_form();
      }

      /// set next level companies
      this[`companies_level_${level+1}`] = selected_company.children.filter(item => item.show);

      this.$nextTick(() => {
        this.scroll_to_smooth_classname(`level${level+1}-selection`);
      });
    },

    extra_company_ids_changed(company_ids) {
      this.extra_company_ids_selected = company_ids;

      this.assign_company_ids();
    },

    assign_company_ids() {
      this.local_form_page_data.company_ids = [];

      if (this.company_level_1_select) {
        this.local_form_page_data.company_ids.push(this.company_level_1_select);
      }

      if (this.company_level_2_select) {
        this.local_form_page_data.company_ids.push(this.company_level_2_select);
      }

      if (this.company_level_3_select) {
        this.local_form_page_data.company_ids.push(this.company_level_3_select);
      }

      if (this.company_level_4_select) {
        this.local_form_page_data.company_ids.push(this.company_level_4_select);
      }

      this.local_form_page_data.company_ids = this.local_form_page_data.company_ids.concat(this.extra_company_ids_selected);

      this.local_form_page_data = { ...this.local_form_page_data };
    },

    scroll_to_smooth_classname(classname) {
      var that = this;
      setTimeout(function() {
        const el = that.$el.getElementsByClassName(classname)[0];

        if (el) {
          const yOffset = -70;
          const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset;

          window.scrollTo({ top: y, behavior: 'smooth' });
        }
      }, 100);
    },


    recursive_setup_extra_companies(node) {
      if (node.extra) {
        this.extra_company_options.push(node);
      }

      if (node.children) {
        for (const child of node.children) {
          this.recursive_setup_extra_companies(child);
        }
      }
    },

    recursive_setup_all_companies_lookup(node) {
      this.all_companies[node.company_id] = node;

      if (node.children) {
        for (const child of node.children) {
          this.recursive_setup_all_companies_lookup(child);
        }
      }
    },

    async setup_registration_hierarchy_view(form_page) {
      try {
        
        console.log('setting up view')

        this.root_company = form_page.company_hierarchy;
        this.loading = false;

        /// setup all lookups per company_id
        this.all_companies = {};
        this.recursive_setup_all_companies_lookup(this.root_company);


        this.company_select_labels = [' ',' ',' ',' ',' '];

        if (this.form_page.texts && this.form_page.texts.company_select_labels) {
          this.company_select_labels = this.form_page.texts.company_select_labels;
        }

        this.companies_level_1 = this.root_company.children;
        this.root_company_visual_select = this.root_company.company_id;

        this.recursive_setup_extra_companies(this.root_company);

        this.extra_company_options.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }

          return 1;
        });

      }
      catch (err) {
        this.error_occured = 'setup_registration_hierarchy_view exception';
        this.loading = false;
        console.error(err);
      }
    },

  }
};
</script>


<style lang="css" scoped>

.boxed-header {
  font-weight: bold;
}

.boxed-select {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  padding-left: 12px;
  padding-top: 12px;
}

.fancy-box {
  padding: 8px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  margin-right: 32px;
}
</style>
