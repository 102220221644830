<template>
  <div>
    
    <div v-if="loading">
      <div class="d-flex justify-content-center mb-3">
        <b-spinner label="Loading..."></b-spinner>
      </div>
    </div>

    <div v-else>

      <div v-if="error">
        <div class="row">
          <div class="col-12 text-center">
            <i class="fa fa-minus-circle" style="font-size: 42px; color: #fe0000;"></i>
          </div>
        </div>
        <div class="row" style="margin-top: 15px; border-top: 1px solid #ebedf2; padding-top: 20px;">
          <div class="col-12 text-center">
            <h4 class="error-header" style="text-align: center;">{{ error_header }}</h4>
            <br />
            <p>
              {{ error_text }}
            </p>
          </div>
        </div>
      </div>

      <div v-else>
        <!-- Actual page content, if not loading and no errors -->
        <div v-html="form_page.html"></div>

        <!-- The page will contain its own validation -->
        <!-- If a page is valid, the next_step event will trigger when the user has proceeded from the step -->

        <FormPagePersonnr v-if="local_page_form_data && form_page.type === 'PERSONNR'"
          :form_page_data="local_page_form_data"
          :form_page="form_page"
          @next="next_step"
          @back="back"
          @change="changed"
        />

        <FormPageQuestions v-if="local_page_form_data && form_page.type === 'QUESTIONS'"
          :form_page_data="local_page_form_data"
          :form_page="form_page"
          @next="next_step"
          @back="back"
          @change="changed"
        />

        <FormPageCompanySelect v-if="local_page_form_data && form_page.type === 'COMPANY_SELECT'"
          :form_page_data="local_page_form_data"
          :form_page="form_page"
          @next="next_step"
          @back="back"
          @change="changed"
        />

        <FormPageConfirm v-if="local_page_form_data && form_page.type === 'CONFIRM'"
          :form_page_data="local_page_form_data"
          :form_page="form_page"
          @next="confirmed"
          @back="back"
          @change="changed"
        />

        <FormPagePersonDetails v-if="form_page.type === 'PERSON_DETAILS'"
          :form_page_data="local_page_form_data"
          :form_page="form_page"
          @next="next_step"
          @back="back"
          @change="changed"
        />
<!--
        
        <FormPageFamily v-if="form_page.type === 'FAMILY'"
          :form_page_data="local_page_form_data"
          :form_page="form_page"
          @next="next_step"
          @back="back"
          @change="changed"
        />

        <FormPageParents v-if="form_page.type === 'PARENTS'"
          :form_page_data="local_page_form_data"
          :form_page="form_page"
          @next="next_step"
          @back="back"
          @change="changed"
        />-->

        <!--<FormPageCompanyDetails v-if="form_page.type === 'COMPANY_DETAILS'"
          :form_page_data="local_page_form_data"
          :form_page="form_page"
          @next="next_step"
          @back="back"
        />-->
<!--
        <FormPageShop v-if="form_page.type === 'SHOP'"
          :form_page_data="local_page_form_data"
          :form_page="form_page"
          @next="next_step"
          @back="back"
          @change="changed"
        />

        <FormPageDonation v-if="form_page.type === 'DONATION'"
          :form_page_data="local_page_form_data"
          :form_page="form_page"
          @next="next_step"
          @back="back"
          @change="changed"
        />

        <FormPageTickets v-if="form_page.type === 'TICKETS'"
          :form_page_data="local_page_form_data"
          :form_page="form_page"
          @next="next_step"
          @back="back"
          @change="changed"
        />

        <FormPagePayment v-if="form_page.type === 'PAYMENT'"
          :form_page_data="local_page_form_data"
          :form_page="form_page"
          @next="next_step"
          @back="back"
          @change="changed"
        />

        
-->

      </div>

    </div>

  </div>
</template>

<script>
import dayjs from 'dayjs';
import axios from 'axios';

import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

//import FormPagePayment from '@/view/pages/public/forms/pages/FormPagePayment.vue';
//import FormPageTickets from '@/view/pages/public/forms/pages/FormPageTickets.vue';
//import FormPageDonation from '@/view/pages/public/forms/pages/FormPageDonation.vue';
//import FormPageShop from '@/view/pages/public/forms/pages/FormPageShop.vue';
//import FormPageParents from '@/view/pages/public/forms/pages/FormPageParents.vue';
//import FormPageFamily from '@/view/pages/public/forms/pages/FormPageFamily.vue';
import FormPageConfirm from '@/view/pages/public/forms/pages/FormPageConfirm.vue';
import FormPagePersonDetails from '@/view/pages/public/forms/pages/FormPagePersonDetails.vue';
import FormPageQuestions from '@/view/pages/public/forms/pages/FormPageQuestions.vue';
import FormPagePersonnr from '@/view/pages/public/forms/pages/FormPagePersonnr.vue';
import FormPageCompanySelect from '@/view/pages/public/forms/pages/FormPageCompanySelect.vue';

export default {
  name: 'FormPage',

  props: {
    /*

    local_page_form_data contains the current page local data, not the array of all pages
     
    form_data contains the data of ALL pages
    form_data: [
      {
        type: 'PERSONNR',
        in_personnr: '19910103-3422',
        valid: true,
      },
      {
        type: 'PERSON_DETAILS',
        firstname: 'John',
        lastname: 'Doe',
        email: 'johndoe@sdasd.com',
        valid: true
      }, ...
    ]
     */
    form_data: { /* data for the entire form, previously entered, all pages */
      type: Array,
      default: () => ([])
    },
    /*

    form_page: {
      id              Int     @id @default(autoincrement())
      form_id         Int     @db.Int
      name            String  @db.VarChar(128)
      type            FORM_PAGE_TYPE
      created_at      String  @db.VarChar(64)
      index           Int     @default(0) @db.Int /// index of the page

      form            ml_form @relation(fields: [form_id], references: [id])
      properties      ml_form_page_property_group[] /// <- if QUESTIONS
    }
    */
    form_page: { /* page definition for this page, ml_form_page */
      type: Object,
      default: () => ({})
    }
  },

  components: {
    FormPagePersonnr,
    FormPageCompanySelect,
    FormPageQuestions,
    FormPagePersonDetails,
    FormPageConfirm
    /*
    FormPageFamily,
    FormPageParents,
    FormPageShop,
    FormPageDonation,
    FormPageTickets,
    FormPagePayment,
    */
  },

  mixins: [ toasts ],

  data() {
    return {
      error: false,
      loading: true,

      error_header: null,
      error_text: null,

      local_page_form_data: {}

    };
  },
  mounted() {
    // mounting the page should assign the previously assigned form data
    this.local_page_form_data = { ...this.form_data[this.form_page.index] }

    this.loading = false;

    console.log('mounted page', this.local_page_form_data);
  },

  computed: {
    
  },

  watch: {
    form_data: {
      handler(newValue) {
        console.log('FormPage watcher trigger', this.form_data);
        this.local_page_form_data = { ...this.form_data[this.form_page.index] }
      },
      deep: true
    },
    form_page: {
      handler(newValue) {
        this.local_page_form_data = { ...this.form_data[this.form_page.index] }
      },
      deep: true
    }
  },
  methods: {

    force_update_form_data(form_data) {
      this.local_page_form_data = { ...form_data[this.form_page.index] }
    },

    changed(page_form_data) {
      this.local_page_form_data = { ...page_form_data };

      this.$emit('change', this.form_page, this.local_page_form_data);
    },

    confirmed(form_page, page_form_data) {
      this.local_page_form_data = { ...page_form_data }; 
      
      this.$emit('confirmed', form_page, this.local_page_form_data);
    },

    /** form_page: ml_form_page, data: step data entered by user for step */
    next_step(form_page, page_form_data) {
      this.local_page_form_data = { ...page_form_data }; 
      
      this.$emit('next', form_page, this.local_page_form_data);
    },

    back() {
      this.$emit('back', this.form_page, this.local_page_form_data);
    },

  }
};
</script>
